<template>
  <div>
    <ik-data-table
      ref="table"
      :custom-on-row-click="getEditResource"
      :entity-name="$t('resource')"
      :model="model"
      :use-add-action="false"
      :use-default-action="false"
      @reloaded="infoReloaded"
    >
      <template #primaryButton>
        <v-btn
          color="primary lighten-4 black--text"
          elevation="0"
          @click="newAdd"
        >
          <div
            class="d-flex align-center"
          >
            <v-icon
              left
            >
              mdi-plus-circle-outline
            </v-icon>
            {{ $t('newRawMaterial') }}
          </div>
        </v-btn>
      </template>
      <template
        #footer="{selectItems}"
      >
        <v-btn
          class="ml-2"
          style="background-color: white;"
          :style="!$vuetify.breakpoint.mobile ? '' : 'color: black !important;'"
          :color="!$vuetify.breakpoint.mobile ? 'black' : 'white'"
          elevation="0"
          outlined
          :small="$vuetify.breakpoint.mobile"
          @click="resourceUploadDialog = true"
        >
          <v-icon
            color="black"
            left
          >
            mdi-cloud-upload
          </v-icon>
          {{ $t('resourceImport') }}
        </v-btn>
      </template>
      <template #item.delete="{ item }">
        <v-icon
          @click.stop="deleteResource(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </ik-data-table>
    <v-dialog
      v-model="newAddResource"
      max-width="600"
      persistent
    >
      <v-card class="pa-4">
        <div class="d-flex align-center mb-4">
          <template v-if="editItemId >0">
            <div class="text-h3 font-weight-bold">
              {{ $t('editResource') }}
            </div>
          </template>
          <template v-else>
            <div class="text-h3 font-weight-bold">
              {{ $t('newRawMaterial') }}
            </div>
          </template>
          <v-spacer/>
          <div>
            <v-btn
              icon
              @click="closeDialog"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <v-form
          ref="firstForm"
          v-model="firstValid"
          lazy-validation
        >
          <div class="text-h4 mb-2">
            {{ $t('rawMaterialName') }}
          </div>
          <div class="d-flex align-center mb-4">
            <v-text-field
              v-model="resourceName"
              :rules="rules"
              append-icon="mdi-text-short"
              dense
              hide-details
              outlined
            />
            <v-icon
              class="mr-4 ml-4"
            >
              mdi-lead-pencil
            </v-icon>
          </div>
          <div class="text-h4 mb-2">
            {{ $t('defaultBarcode') }}
          </div>
          <div class="d-flex align-center mb-4">
            <v-text-field
              v-model="resourceCode"
              :rules="rules"
              append-icon="mdi-text-short"
              dense
              hide-details
              outlined
            />
            <v-icon
              class="mr-4 ml-4"
            >
              mdi-barcode
            </v-icon>
          </div>
          <div class="text-h4 mb-2">
            {{ $t('maxUnitPrice') }}
          </div>
          <div class="d-flex align-center mb-4">
            <v-text-field
              v-model="unitPrice"
              :rules="rules"
              append-icon="mdi-text-short"
              dense
              hide-details
              outlined
              type="number"
              @keydown="keyboardRules"
            />
            <v-icon
              class="mr-4 ml-4"
            >
              mdi-cash
            </v-icon>
          </div>
        </v-form>
        <template>
          <div class="mb-1">
            <div class="text-body-2 mt-4 font-weight-bold">
              {{ $t('ListOfHighLevelUnits') }}
            </div>
          </div>

          <div
            v-for="(a,index) in resourceList"
            :key="a.key"
            class="d-flex align-center mb-1"
          >
            <v-form
              ref="secondForm"
              v-model="valid"
              lazy-validation
            >
              <div class="d-flex">
                <v-text-field
                  v-model="a.normalUnit"
                  :placeholder="$t('unit')"
                  dense
                  hide-details
                  outlined
                />
                <div class="mr-2 ml-2 mt-2">
                  <v-icon>
                    mdi-transfer-right
                  </v-icon>
                </div>
                <v-text-field
                  v-model="a.nextLevelUnit"
                  :placeholder="$t('numberOf')"
                  dense
                  hide-details
                  outlined
                  step="1"
                  type="number"
                  @keydown="keyboardRules"
                />
                <div class="mr-2 ml-2 mt-2">
                  <v-icon>
                    mdi-power-on
                  </v-icon>
                </div>
                <div class="mr-2">
                  <v-text-field
                    v-model="a.note"
                    :placeholder="$t('note')"
                    dense
                    hide-details
                    outlined
                  />
                </div>
              </div>
            </v-form>
            <div style="width: 60px">
              <v-btn
                :disabled="resourceList.length===0||(!a.normalUnit&&!a.nextLevelUnit&&!a.note)"
                class="ml-1"
                icon
                @click="resourceList.splice(index,1)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <v-form
          ref="minUnitForm"
          v-model="minUnitValid"
          lazy-validation
        >
          <div class="text-h4 mb-2">
            {{ $t('SmallestUnit') }}
          </div>
          <div class="d-flex">
            <v-text-field
              v-model="minUnit"
              :placeholder="$t('unit')"
              :rules="rules"
              dense
              hide-details
              outlined
            />
            <div class="mr-2 ml-2 mt-2">
              <v-icon>
                mdi-transfer-right
              </v-icon>
            </div>
            <v-text-field
              v-model="minUnitCount"
              :placeholder="$t('numberOf')"
              :rules="rules"
              dense
              disabled
              hide-details
              outlined
              step="1"
              type="number"
              @keydown="keyboardRules"
            />
            <div class="mr-2 ml-2 mt-2">
              <v-icon>
                mdi-power-on
              </v-icon>
            </div>
            <div class="mr-2">
              <v-text-field
                v-model="minUnitNote"
                :placeholder="$t('note')"
                dense
                hide-details
                outlined
              />
            </div>
            <div class="mr-3 ml-3 mt-2">
              <v-icon>
                mdi-scale-balance
              </v-icon>
            </div>
          </div>
        </v-form>
        <div class="mt-4 d-flex mr-4">
          <v-spacer/>
          <template v-if="editItemId > 0">
            <v-btn
              :disabled="!valid || !firstValid || !minUnitValid"
              class="primary mr-2"
              @click="editResource"
            >
              {{ $t('save') }}
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              :disabled="!valid || !firstValid || !minUnitValid"
              class="primary mr-2"
              @click="save"
            >
              {{ $t('save') }}
            </v-btn>
          </template>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="resourceUploadDialog"
      max-width="600px"
    >
      <v-card class="pa-4">
        <div class=" d-flex">
          <div class="text-h3 font-weight-bold">
            {{ $t('resourceImport') }}
          </div>
          <v-spacer/>
          <div>
            <v-btn
              icon
              @click="resourceUploadDialog = false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <template>
          <v-file-input
            :label="$t('clickToChoiceFile')"
            @change="processFile"
          />
        </template>
        <div class="d-flex">
          <v-spacer/>
          <v-btn
            class="primary  mt-4 lighten-2"
            elevation="0"
            style="border-radius: 36px"
            @click="importExcel"
          >
            {{ $t('save') }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <global-listener @submit="enterPressed"/>
    <loading-dialog :just-wait="justWait"/>
  </div>
</template>

<script>
  import Resource from '@/model/storage/Resource'
  import { IkDataTable } from 'metaflow-js'
  import IKUtils from 'innerken-js-utils'
  import Unit from '@/model/storage/Unit'
  import UnitSet, { editUnits } from '@/model/storage/UnitSet'
  import { showSuccessMessage, sureToDelete } from '@/common/utils'
  import Dish from '@/model/dish/Dish'
  import { importResourceExecl } from '@/common/Utlis/api'
  import GlobalListener from '@/views/AadenPages/dishes/dish/widget/GlobalListener'
  import { keyBy } from 'lodash'
  import LoadingDialog from '@/composable/LoadingDialog'
  import i18n from '@/i18n'

  let counter = 0
  const defaultResource = {
    normalUnit: '',
    nextLevelUnit: '',
    note: '',
  }

  export default {
    name: 'ResourceListPage',
    components: {
      LoadingDialog,
      IkDataTable,
      GlobalListener,
    },
    data: function () {
      return {
        justWait: false,
        minUnitValid: true,
        minUnitId: null,
        minUnit: null,
        minUnitCount: 1,
        minUnitNote: '',
        firstValid: true,
        rules: [
          v => !!v || i18n.t('PleaseEnterTheUnit'),
        ],
        editItem: null,
        editItemId: -1,
        importFile: null,
        resourceUploadDialog: false,
        valid: true,
        unitPrice: null,
        resourceCode: null,
        resourceName: null,
        resourceList: [this.createDefaultAttribute()],
        newAddResource: null,
        model: Resource,
      }
    },
    computed: {
      needToAdd () {
        return this.resourceList.filter(it => it.normalUnit)
      },
    },
    watch: {
      newAddResource (val) {
        if (!val) {
          this.editItemId = -1
          this.$refs.firstForm.reset()
          this.$refs.minUnitForm.reset()
          this.minUnitNote = ''
        } else {
          this.minUnitCount = 1
        }
      },
      resourceList: {
        deep: true,
        handler (val) {
          const emptyNode = val.filter(it => !it.normalUnit && !it.nextLevelUnit && !it.note).length
          if (emptyNode === 0) {
            this.resourceList.push(this.createDefaultAttribute())
          }
        },
      },
    },
    methods: {
      keyboardRules (e) {
        if (e.key === '-') {
          e.returnValue = false
        }
      },
      async infoReloaded (itemList) {
        const list = itemList.map(it => {
          it.defaultBarcode = it.defaultBarcode.toLowerCase()
          return it
        })
        this.resourceDict = keyBy(list, 'defaultBarcode')
      },
      async enterPressed (key) {
        const res = this.resourceDict[key.toLowerCase()]
        if (res) {
          await this.getEditResource(res)
        } else {
          this.newAddResource = true
          this.resourceCode = key
        }
      },
      async deleteResource (item) {
        await sureToDelete(async () => {
          await Resource.remove(item.id)
          showSuccessMessage(this.$t('delete_success'))
          this.$refs.table.reload()
        })
      },
      async getEditResource (item) {
        this.editItem = item
        this.editItemId = item.id
        this.newAddResource = true
        this.resourceName = item.name
        this.resourceCode = item.defaultBarcode
        const list = item.unitSetWithUnit.resourceUnits.map(it => {
          it.normalUnit = it.name
          it.nextLevelUnit = it.nextLevelFactor
          return it
        })
        this.resourceList = list.slice(0, -1)
        const result = list.slice(-1)
        this.minUnit = result[0].name
        this.minUnitCount = result[0].nextLevelFactor
        this.minUnitNote = result[0].note
        this.minUnitId = result[0].id
        const res = item.unitSetWithUnit.resourceUnits.map(it => {
          return it.nextLevelUnit
        }).reduce((preV, curV) => preV * curV)
        this.unitPrice = (item.lowestUnitPrice * res).toFixed(2)
      },
      async editResource () {
        if (this.$refs.firstForm.validate() && this.$refs.minUnitForm.validate()) {
          this.justWait = true
          let counter = 1
          const unitIdList = []
          await editUnits(this.editItem.resourceUnitSetId, [])
          await Unit.edit({
            id: this.minUnitId,
            name: this.minUnit,
            nextLevelFactor: this.minUnitCount,
            note: this.minUnitNote,
          })
          for (const a of this.needToAdd) {
            a.value = counter++
            a.name = a.normalUnit
            a.nextLevelFactor = a.nextLevelUnit
            if (a.normalUnit && a.nextLevelUnit) {
              unitIdList.push((await Unit.add(a)).id)
            }
          }
          unitIdList.push(this.minUnitId)
          await editUnits(this.editItem.resourceUnitSetId, unitIdList)
          const res = await Resource.edit({
            id: this.editItemId,
            name: this.resourceName,
            image: '',
            defaultBarcode: this.resourceCode,
            resourceUnitSetId: this.editItem.resourceUnitSetId,
            lowestUnitPrice: this.unitPrice,
          })
          this.justWait = false
          if (res.code === 400) {
            IKUtils.showError(i18n.t('BardcodeAllreadyUsed'))
          } else {
            this.$refs.table.reload()
            showSuccessMessage()
            this.closeDialog()
          }
        }
      },
      processFile (event) {
        this.importFile = event
      },
      async importExcel () {
        const res = (await Dish.getList()).map(it => {
          return { code: it.code, dishId: it.dishId }
        })
        await importResourceExecl({
          codes: JSON.stringify(res),
          file: this.importFile,
        })
        this.resourceUploadDialog = false
        this.$refs.table.reload()
      },
      closeDialog () {
        this.newAddResource = false
      },
      async save () {
        if (this.$refs.firstForm.validate() && this.$refs.minUnitForm.validate()) {
          this.justWait = true
          let counter = 1
          const unitIdList = []
          for (const a of this.needToAdd) {
            a.value = counter++
            a.name = a.normalUnit
            a.nextLevelFactor = a.nextLevelUnit
            if (a.normalUnit && a.nextLevelUnit) {
              unitIdList.push((await Unit.add(a)).id)
            }
          }
          const res = await Unit.add({
            name: this.minUnit,
            nextLevelFactor: this.minUnitCount,
            note: this.minUnitNote,
          })
          unitIdList.push(res.id)
          const unitSet = await UnitSet.add({
            name: this.resourceName,
            note: '',
            unitIds: unitIdList,
          })
          const result = await Resource.add({
            name: this.resourceName,
            image: '',
            defaultBarcode: this.resourceCode,
            resourceUnitSetId: unitSet.id,
            lowestUnitPrice: this.unitPrice,
          })
          this.justWait = false
          if (result.code === 400) {
            IKUtils.showError(i18n.t('BardcodeAllreadyUsed'))
          } else {
            this.$refs.table.reload()
            showSuccessMessage()
            this.closeDialog()
          }
        }
      },
      createDefaultAttribute () {
        const obj = IKUtils.deepCopy(defaultResource)
        obj.key = counter++
        return obj
      },
      newAdd () {
        this.newAddResource = true
        this.resourceList = [this.createDefaultAttribute()]
      },
    },
  }
</script>

<style scoped>

</style>
